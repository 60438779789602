@use "plain";

@import "toggle";
  @import "dark";ul.pager {
    text-align: center;

    li {
        list-style: none;
        display: inline-block;
        padding: 1em;
    }
}
