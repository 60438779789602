@use 'ext/solarized-light';

.highlight {
  background-color: #f4f4f4;
}

div.highlight {
  padding: 5px 20px;
}
pre.highlight, code {
  font-family: 'Consolas', Menlo, monospace;
  line-height: 1.4em;
  tab-size: 4;
}
.highlight code {
  color: inherit;
}
code {
  color: green;
}
